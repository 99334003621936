import React from "react";
import { LinkPageLink } from "./types";


const JoinWaitlistAnimation = () => {
  const [currentMessage, setCurrentMessage] = React.useState(0);
  const spanRef = React.useRef<HTMLSpanElement>(null);
  const messages = ["Join my waitlist!", "Sold out"];
  const [supportsAnimations, setSupportsAnimations] = React.useState(true);

  React.useEffect(() => {
    // Check for Web Animations API support
    const isSupported = 'animate' in document.createElement('div');
    setSupportsAnimations(isSupported);
  }, []);

  React.useEffect(() => {
    if (!supportsAnimations) return;

    const animate = async () => {
      if (!spanRef.current) return;
      
      // Fade in
      await spanRef.current.animate(
        [{ opacity: 0 }, { opacity: 1 }],
        { duration: 200, fill: 'forwards' }
      ).finished;

      // Wait 1 second
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Fade out
      await spanRef.current.animate(
        [{ opacity: 1 }, { opacity: 0 }],
        { duration: 200, fill: 'forwards' }
      ).finished;

      // Switch message and repeat
      setCurrentMessage(prev => (prev + 1) % messages.length);
    };

    let isRunning = true;
    const startAnimation = async () => {
      while (isRunning) {
        await animate();
      }
    };
    
    startAnimation();
    return () => { isRunning = false; };
  }, [supportsAnimations]);

  if (!supportsAnimations) {
    return <span>Join my waitlist!</span>;
  }

  return (
    <span ref={spanRef} style={{ opacity: 0 }}>
      {messages[currentMessage]}
    </span>
  );
};

const AvailableSubText = ({ link }: { link: LinkPageLink }) => {
  if (link.available === 0 && !link.waitlist_enabled) {
    return <span>No spots left</span>;
  }

  if (link.available === 0 && link.waitlist_enabled) {
    return <JoinWaitlistAnimation />;
  }

  return <span>{link.available} spot{link.available === 1 ? '' : 's'} left</span>;
}

export default ({ link }: { link: LinkPageLink }) => {
  const extraClasses = [];

  if (link.wiggle) {
    extraClasses.push("wiggle");
  }

  let borderColor = link.highlight && link.highlight_color ? link.highlight_color : "white";
  let color = link.highlight && link.highlight_color ? link.highlight_color : "inherit";

  return (
    <a href={link.url}
      className={ "text-black no-underline border border-white flex flex-col justify-center font-semibold bg-white mb-4 p-4 shadow rounded " + extraClasses.join(" ") }
    style={ { minHeight: 50, borderColor, color } }>

      <div>
        {link.name}
      </div>

      {link.available !== null ? (
        <div className="text-xs text-black mt-1">
          <AvailableSubText link={link} />
        </div>
      ) : null}

  </a>
  );
};
