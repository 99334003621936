import React, { useEffect } from "react";
import { useQuery, compress, buildQuery } from "micro-graphql-react";

import NotFound from "./NotFound";
import Link from "./Link";
import { LinkPageLink } from "./types";


const QUERY = compress`
  query Links($url: String!) {
  	links(slug: $url) {
      name
      profile_url
      links {
        name
        url
        highlight
        highlight_color
        wiggle
        available
        waitlist_enabled
      }
    }
  }
`;

const App = () => {
  let path = window.location.pathname.replace(/^\//, "");

  const { data, error, loaded } = useQuery(buildQuery(QUERY, { url: path}));
  useEffect(() => {
    if (loaded && data.links) {
      window.document.title = data.links.name;
    }
  }, [ loaded, data ]);

  if (error) {
    return <NotFound />
  }

  if (!loaded) {
    return null;
  }

  return (
    <div className="text-center lg:max-w-3xl lg:mx-auto ">

      <div className="flex justify-center flex-col mt-12 px-3">
        { data.links.profile_url && (
          <div className="flex justify-center mb-4">
            <div className="rounded-full overflow-hidden border-2 shadow-lg border-white w-24 h-24 mx-auto mb-2" >

              <img alt={data.links.name} src={data.links.profile_url} className="items-center justify-center object-cover w-24 h-24" />
            </div>
          </div>
        ) }

        <div className="mb-8 font-semibold text-xl">{ data.links.name }</div>

        {data.links.links.map((link: LinkPageLink, i: number)  => <Link link={link} key={i} />)}

      </div>

      <div className="my-16 text-center flex flex-col justify-center text-xs">
          <p>Powered by <a className="text-blue-500" href="https://usesession.com">Session</a></p>
      </div>

    </div>
  );
};

export default App;
